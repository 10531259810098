define(['lodash', 'prop-types', 'santa-components', 'componentsCore'], function (_, PropTypes, santaComponents, componentsCore) {
    'use strict';

    const displayNone = {style: {display: 'none'}};

    const assignLimits = (props, compData) => {
        if (props.type === 'number') {
            _.assign(props, {
                min: compData.min,
                max: compData.max
            });
        }

        if (compData.maxLength) {
            _.assign(props, {
                maxLength: compData.maxLength
            });
        }
    };

    const assignStep = (props, compData) => {
        if (compData.step && props.type === 'number') {
            _.assign(props, {
                step: compData.step
            });
        }
    };

    function getInputProps() {
        const compProps = this.props.compProp;
        const compData = this.props.compData;

        // TODO: move the following compProps to an inputMixin ? : required, autoComplete, readOnly, isDisabled, label
        const props = {
            type: compData.textType,
            name: compData.name || this.props.structure.nickname,
            value: this.state.value,
            onChange: this._handleChange,
            onBlur: this._handleBlur,
            onFocus: this._handleFocus,
            onClick: this._handleClick,
            onKeyDown: this._handleKeyDown,
            onInput: this._handleInput,
            disabled: compProps.isDisabled,
            required: compProps.required,
            readOnly: compProps.readOnly,
            placeholder: compData.placeholder || compProps.placeholder,
            tabIndex: compProps.tabIndex
        };

        if (compData.pattern) {
            _.assign(props, {
                pattern: compData.pattern
            });
        }

        assignLimits(props, compData);
        assignStep(props, compData);

        if (compProps.autoComplete && props.type !== 'password') {
            props.autoComplete = 'on';
        }

        return props;
    }

    const getPublicState = function (state) {
        return santaComponents.mixins.validatableMixin.getPublicState(state);
    };

    /**
     * @class components.BaseTextInput
     * @extends {core.skinBasedComp}
     */
    const BaseTextInput = {
        mixins: [componentsCore.mixins.skinBasedComp, santaComponents.mixins.runTimeCompData, santaComponents.mixins.validatableMixin.validatable, santaComponents.mixins.compStateMixin(getPublicState)],
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            shouldResetComponent: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetComponent,
            isPreset: PropTypes.bool,
            onChange: PropTypes.func,
            message: PropTypes.string
        },
        BASE_TEXT_BEHAVIORS: _.assign({}, santaComponents.mixins.validatableMixin.VALIDATABLE_BEHAVIORS),

        // TODO: add events for editor: keyup, keydown, blur, focus, inputChanged (doesn't include 'enter')

        getInitialState() {
            return _.assign(getPublicState(), {
                value: this.props.compData.value
            });
        },

        componentWillReceiveProps(nextProps) {
            if (nextProps.shouldResetComponent && nextProps.shouldResetComponent !== this.props.shouldResetComponent) {
                this.hideValidityIndication();
            }

            const nextState = {};
            if (_.has(nextProps.compData, 'value') && nextProps.compData.value !== this.state.value) {
                nextState.value = nextProps.compData.value;
            }
            this.setState(nextState);
        },

        _handleClick(event) {
            if (this.props.isPreset) {
                event.target.select();
            }
        },

        setCustomValidity(customValidity) {
            this.refs.input.setCustomValidity(customValidity);
        },

        _handleChange(event) {
            const newValue = event.target.value;

            if (newValue === this.state.value) {
                return;
            }

            this.setState({
                value: newValue
            }, function () {
                this.updateData({value: newValue});
            }.bind(this));
            this.hideValidityIndication();
            this.latestChangeEvent = event;
        },

        _handleBlur(event) {
            if (this.props.onChange) {
                this.props.onChange(event);
            }
            this.handleAction('blur', event);
            if (this.latestChangeEvent) {
                this.handleAction('change', this.latestChangeEvent);
                this.latestChangeEvent = null;
            }
            this.showValidityIndication();
        },

        _handleFocus(event) {
            this.handleAction('focus', event);
        },

        _handleKeyDown(event) {
            this.handleAction('keyPress', event);
        },

        _handleInput(event) {
            this.handleAction('onInput', event);
        },

        getBaseTextInputSkinProperties() {
            return {
                input: getInputProps.call(this),
                message: this.props.message ? {
                    children: this.props.message,
                    style: {'whiteSpace': 'normal', display: 'table'}
                } : displayNone
            };
        }
    };

    return BaseTextInput;
});
